import React from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'
import { Button } from 'react-bootstrap'
import DownloadIcon from '@mui/icons-material/Download';
import Nvidia from "../../images/nvidia.webp"
import Amd from "../../images/amd.webp"
import Intel from "../../images/intel.webp"
import DirectX from "../../images/directX.webp"

import styles from "./download.module.css"


function Download() {
  return (
    <div className={styles.content_column}>
      <ImageCarousel />


      <h2 className={styles.title}>Full Client Download</h2>
      <div className={styles.button_text_group}>
        <Button target="_blank" href='https://mega.nz/file/Fz9W2DyK#k7AX5ngACWTvIVDNmqZFQ9qUW1S18SPp9_UFHSGfvJc' className={styles.redirect_button}>MEGASHARE <DownloadIcon /></Button>
        <div>
          <p className={`${styles.white_text} ${styles.overlock_Font}`}>Version 16.00.0000 | File Size: 4.2GB | 60 FPS</p>
          <p className={`${styles.bottom_line} ${styles.overlock_Font}`}><b>Last Updated:</b> September 9th 2024</p>
        </div>
      </div>
      <div className={styles.button_text_group}>
        <Button target="_blank" href='https://drive.google.com/uc?export=download&id=1ldO1q8EviV3uAkl7EBASFzAnBqscGnA-' className={styles.redirect_button}>GOOGLE DRIVE <DownloadIcon /></Button>
        <div>
          <p className={`${styles.white_text} ${styles.overlock_Font}`}>Version 16.00.0000 | File Size: 4.2GB | 60 FPS</p>
          <p className={`${styles.bottom_line} ${styles.overlock_Font}`}><b>Last Updated:</b> September 9th 2024</p>
        </div>
      </div>
      <div className={styles.button_text_group}>
        <Button target="_blank" href='https://www.mediafire.com/file/n2m51k3n4dr92e9/Lucent_Heart_Rebirth.zip/file' className={styles.redirect_button}>MEDIAFIRE <DownloadIcon /></Button>
        <div>
          <p className={`${styles.white_text} ${styles.overlock_Font}`}>Version 16.00.0000 | File Size: 4.2GB | 60 FPS</p>
          <p className={`${styles.bottom_line} ${styles.overlock_Font}`}><b>Last Updated:</b> September 9th 2024</p>
        </div>
      </div>
      <div className={styles.disclaimer}>
          <h4><span>Disclaimer</span></h4>
          <p className={styles.overlock_Font}>
          Since we have altered many files in order to make our private server, <b>your antivirus or firewall might not want to start our executable files.</b>
          <u>You need to add our private server's folder and the LucentHeart.exe file  to your firewall or antivirus exceptions so that the game can start smoothly.</u>
          </p>
          <p className={styles.overlock_Font}>
            Our default <b>60 FPS</b> client is known to have <u>some visual effect and animation bugs</u>, such as a delay in system messages. 
            These are minor aesthetic issues that we're still trying to fix. 
            If you wish to play in our server using the old <b>30 FPS</b> client you can download the files below.
          </p>
        </div>

      
      <h2 className={styles.title}>Other Downloads</h2>
      <div className={styles.button_text_group}>
        <Button target="_blank" href='https://drive.google.com/uc?export=download&id=1wmQ3jtXNV8XwN34umZZlyiLRLRrKFGYC' className={styles.redirect_button}>Alternative UI <DownloadIcon /></Button>
        <div>
          <p className={`${styles.white_text} ${styles.overlock_Font}`}>Download these files and paste them in the
          <br />appropriate folders in order to play the game
          <br />with the alternative UI that was made by <b>Aleh</b>.</p>
          <p className={`${styles.bottom_line} ${styles.overlock_Font}`}><b>Last Updated:</b> October 3rd 2023</p>
        </div>
      </div>
      <div className={styles.button_text_group}>
        <Button target="_blank" href='https://drive.google.com/drive/folders/1JfttcJbZOurYUviwBXqXfoshrCONl5D5?usp=sharing' className={styles.redirect_button}>中文文字文件 <DownloadIcon /></Button>
        <div>
          <p className={`${styles.white_text} ${styles.overlock_Font}`}>下載這些檔案並將其貼到“data”資料夾
          <br />中以獲得中文介面。請記住，每當有更新時,
          <br />您都需要再次替換文件, 因為啟動
          <br />器會下載英文文件。</p>
          <p className={`${styles.bottom_line} ${styles.overlock_Font}`}><b>Last Updated:</b> December 12th 2024</p>
        </div>
      </div>
      <div className={styles.button_text_group}>
        <Button target="_blank" href='https://drive.google.com/drive/folders/1QTlVks_OFAa-JGXWvqH9zY9c1ecKWlxW?usp=sharing' className={styles.redirect_button}>日本語テキスト <DownloadIcon /></Button>
        <div>
          <p className={`${styles.white_text} ${styles.overlock_Font}`}>日本語インターフェースでゲームを
          <br />プレイするには、これらのファイルを
          <br />ダウンロードして「data」フォルダーに
          <br />貼り付けます。ランチャーは英語のファ
          <br />イルをダウンロードするため、更新があ
          <br />るたびにファイルを再度置き換える必
          <br />要があることに注意してください。</p>
          <p className={`${styles.bottom_line} ${styles.overlock_Font}`}><b>Last Updated:</b> December 12th 2024</p>
        </div>
      </div>


      <h2 className={styles.title}>System Requirements</h2>
      <h3 className={styles.requirements_title}>Minimum: </h3>
      <div className={styles.requirements}>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>OS:</span> Windows XP SP3 and newer </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Processor:</span> Pentium 4 1.3 GHz or greater </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Memory:</span> 512 MB RAM or more </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Hard Drive:</span> 4 GB or more </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Graphics:</span> NVidia GeForce 3 / ATI Radeon 8500 or greater </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Internet Connection:</span>Cable Modem ADSL 512K or greater</h5>
      </div>

      <h3 className={styles.requirements_title}>Recommended: </h3>
      <div className={styles.requirements}>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>OS:</span> Windows XP SP3 and newer </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Processor:</span> Pentium 4 2.0 GHz or greater </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Memory:</span> 1 GB RAM or more </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Hard Drive:</span> 5 GB or more </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Graphics:</span> NVidia GeForce FX 5900 / ATI 9200 or greater </h5>
        <h5 className={`${styles.white_text} ${styles.overlock_Font}`}><span>Internet Connection:</span>Cable Modem ADSL 1MB or greater</h5>
      </div>
      <h2 className={styles.title}>Are Your Drivers Up to Date?</h2>
      <div className={styles.image_link_group}>
        <a href="https://www.nvidia.com/Download/index.aspx?lang=en-us" target="_blank" className={styles.image_link}>
          <img src={Nvidia} />
        </a>
        <a href="https://www.amd.com/en/support" target="_blank" className={styles.image_link}>
          <img src={Amd} />
        </a>
        <a href='https://www.intel.com/content/www/us/en/download-center/home.html' target="_blank" className={styles.image_link}>
          <img src={Intel} />
        </a>
        <a href='https://www.microsoft.com/en-us/download/details.aspx?id=35' target="_blank" className={styles.image_link}>
          <img src={DirectX} />
        </a>
      </div>
      <br/>
    </div>
  )
}

export default Download